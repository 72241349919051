import classes from "./App.css";
import NavBar from "./layouts/nav/NavBar";
import Footer from "./layouts/footer/Footer";
import HomePage from "./pages/home/homePage";
import LoginPage from "./pages/authentication/LoginPage";
import { Routes, Route } from "react-router-dom";
import SignupPage from "./pages/authentication/SignupPage";
import ProgramsPage from "./pages/Programs/Programs";
import ForgetPasswordPage from "./pages/authentication/ForgetPasswordPage";
// import Blogs from "./pages/blogs/BlogsPage";
// import BlogPage from "./pages/blogs/blogpage/BlogPage";
import ProgramDetails from "./pages/programDetails/ProgramDetails";
import Profile from "./pages/profile/Profile";
import Reviews from "./pages/reviews/Reviews";
import AboutUs from "./pages/aboutUs/AboutUs";
import PersonalInfo from "./pages/profile/Personal Information/PersonalInfo";
import HideComponent from "./layouts/hideComponent/HideComponent";
import NotFound from "./layouts/notFound/NotFound";
import GenericForm from "./generic components/generic form/GenericForm"
import AdminPage from "./pages/admin/AdminPage"
import Policy from "./pages/policy/Policy";
import DummyForm from "./pages/dummy form/DummyForm";
import MyToaster from "./generic components/toaster/MyToaster";
function App() {

  return (
    <>
      <MyToaster />
      <HideComponent>
        <NavBar />
      </HideComponent>
      <Routes>
        <Route path="/form" element={<DummyForm />} />
        <Route path="/adminForm" element={<GenericForm />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/PersonalInfo" element={<PersonalInfo />} />
        <Route path="/forgetPassword/:id" element={<ForgetPasswordPage />} />
        <Route path="/programs" element={<ProgramsPage />} />
        <Route path="/programs/:id" element={<ProgramDetails />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/about" element={<AboutUs />} />
        {/* <Route exact path="/blogs" element={<Blogs />} /> */}
        {/* <Route path="/blogs/:id" element={<BlogPage />} /> */}
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/admin/*" element={<AdminPage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <HideComponent>
        <Footer />
      </HideComponent>
    </>
  );
}

export default App;
